import { logout } from 'actions/auth'
import { Layout } from 'antd'
import { UserMenu } from 'components/user/UserMenu'
import { REDIRECT_DOMAIN_PATH } from 'DataSet'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'

const Logo = '/assets/images/MagnifiLogo/Magnifi_Logo_Charcoal_RGB.png'

const { Header } = Layout

class MHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pathname: this.props.location.pathname
    }
  }

  handleGoToLogin = () => {
    this.props.history.push('/login')
  }

  handleLogout = event => {
    event.preventDefault()
    const payload = {
      email: this.props.user.email
    }
    this.props.logout(payload)
  }

  render() {
    const {
      location,
      height,
      query,
      loggedIn,
      loggingIn,
      logoutHandler,
      logo
    } = this.props

    const { pathname } = location

    let LOGO_WILL_BE =
      '/assets/images/MagnifiLogo/Magnifi_Logo_Charcoal_RGB.png'

    console.log(pathname.startsWith('Hello'))
    // const [showMenu, setShowMenu] = React.useState(false)

    return (
      <React.Fragment>
        <Header
          id="magnifi-header-wrapper"
          className="magnifi-header-wrapper headerWrapper"
        >
          <a className="navbar-brand logoWrapper" href={REDIRECT_DOMAIN_PATH}>
            <img
              className="brand-logo"
              src={LOGO_WILL_BE}
              width={height ? '' : '162'}
              height={height}
              alt="magnifi"
            />
          </a>
          <DropdownMenuLinks />

          <div className="profile-anchor">
            <nav className="navbar navbar-expand-md navbar-dark bg-c5 py-0 shadow-0 navBarWrapper">
              <div className="container py-1">
                <UserMenu
                  className="user-menu-popover "
                  onLogout={this.handleLogout}
                  isLoggedIn={this.props.loggedIn}
                  showSinginIcon={this.props.isLoggedIn}
                />
              </div>
            </nav>
          </div>
        </Header>
      </React.Fragment>
    )
  }
}

Header.propType = {
  onLogout: PropTypes.func.isRequired
}

const mapStateToProps = ({ auth, profile }) => ({
  useremail: auth.user.email,
  firstName: auth.user.firstName,
  lastName: auth.user.lastName,

  loggingIn: auth.loggingIn,

  loggedIn: auth.loggedIn,
  authSuccess: auth.authSuccess,
  authFailed: auth.authFailed,
  user: auth.user,
  logoutSuccess: auth.logoutSuccess
  //logo: profile.logo,
})

const mapDispatchToProps = {
  logoutHandler: logout,
  logout
}

const DropdownMenuLinks = () => {
  const [showMenu, setShowMenu] = React.useState(false)
  const dropdownRef = React.useRef(null)
  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowMenu(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])
  const HeaderLink = ({ to, label }) => {
    // get window location
    const pathname = window.location.pathname

    return (
      <div
        onClick={() => {
          setShowMenu(false)
        }}
        style={
          pathname.startsWith(to)
            ? {
              color: 'white',
              backgroundColor: '#007bff',
              width: '100%',
              height: '100%',
              padding: 10
            }
            : {
              color: 'black',
              backgroundColor: '#fff',

              padding: 10
            }
        }
      >
        <Link
          style={
            pathname.startsWith(to)
              ? {
                color: 'white',
                backgroundColor: '#007bff',
                width: '100%',
                height: '100%',
                fontSize: "15px",

              }
              : {
                fontSize: "15px",

                color: 'black',
                backgroundColor: '#fff'
              }
          }
          to={to}
        >
          {label}
        </Link>
      </div>
    )
  }
  return (
    <div
      ref={dropdownRef}
      className="dropdown_menu"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end'
      }}
    >
      <Button
        onClick={() => {
          setShowMenu(!showMenu)
        }}
        className="dropdown_menu__title"
        style={{
          position: 'relative',
          backgroundColor: 'black'
        }}
      >
        Menu
      </Button>
      {showMenu && (
        <div
          className="dropdown_menu__content"
          style={{
            position: 'fixed',
            top: 50,
            zIndex: 100
          }}
        >
          <ul
            style={{
              listStyle: 'none',
              padding: 0,
              margin: 0,
              backgroundColor: 'white',
              border: '1px solid #ccc',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 10
            }}
          >
            <li>
              <HeaderLink to="/populate-details" label="Populate Details" />
            </li>
            <li>
              <HeaderLink
                label={'User Requests'}
                to="/user-requests"
              ></HeaderLink>
            </li>
            <li>
              <HeaderLink label={'Treasury Master'} to="/treasury"></HeaderLink>
            </li>
            <li>
              <HeaderLink label={'Cash Master'} to="/cash"></HeaderLink>
            </li>
            <li>
              <HeaderLink
                label={'Cryptocurrency Master'}
                to="/cryptocurrency"
              ></HeaderLink>
            </li>
            <li>
              <HeaderLink label={'Sponsors'} to="/sponsers"></HeaderLink>
            </li>
            <li>
              <HeaderLink
                label={'Error Accounts'}
                to="/notifications"
              ></HeaderLink>
            </li>
            <li>
              <HeaderLink label={'Trade Basket'} to="/trades"></HeaderLink>
            </li>
            <li>
              <HeaderLink
                label={'Trading Accounts'}
                to="/accounts"
              ></HeaderLink>
            </li>
            <li>
              <HeaderLink
                label={'Update User'}
                to="/update-user"
              ></HeaderLink>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(MHeader)
